import {Directive, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[trDnd]'
})
export class DndDirective {
  @HostBinding('class.dropzone') dropzone = true;
  @HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<FileList>();

  @Input()
  accept = '*';

  @HostListener('document:dragover', ['$event'])
  @HostListener('drop', ['$event'])
  onDragDropFileVerifyZone(event): void {
    if (event.target.matches('dropzone')) {
      // In drop zone. I don't want listeners later in event-chain to meddle in here
      event.stopPropagation();
    } else {
      // Outside of drop zone! Prevent default action, and do not show copy/move icon
      event.preventDefault();
      event.dataTransfer.effectAllowed = 'none';
      event.dataTransfer.dropEffect = 'none';
    }
  }

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt): void {
    if (evt.target.matches('.dropzone')) {
      evt.dataTransfer.effectAllowed = 'copy';
      evt.dataTransfer.dropEffect = 'copy';
      evt.preventDefault();
      evt.stopPropagation();
      this.fileOver = true;
    } else {
      // Outside of drop zone! Prevent default action, and do not show copy/move icon
      evt.preventDefault();
      evt.dataTransfer.effectAllowed = 'none';
      evt.dataTransfer.dropEffect = 'none';
      this.fileOver = false;
    }
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  // Drop listener
  @HostListener('drop', ['$event'])
  public ondrop(evt): void {
      evt.preventDefault();
      evt.stopPropagation();
      this.fileOver = false;
      const files = evt.dataTransfer.files;
      if (files.length > 0 && Array.from(files)
        .every((file: File) => !!file.type.match(this.accept))) {
        this.fileDropped.emit(files);
      }
  }
}
