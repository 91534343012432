import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

export const TAETIGKEIT_MAX_LENGTH = 500;

@Component({
  selector: 'app-cv-volunteering',
  templateUrl: './cv-volunteering.component.html',
  styleUrls: ['./cv-volunteering.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CvVolunteeringComponent {
  @Input()
  group: UntypedFormGroup;

  @Output()
  remove = new EventEmitter<void>();
  constructor() { }

  get taetigkeitCount(): number {
    return this.group.get('taetigkeit').value.length;
  }

  get taetigkeitMaxLength(): number {
    return TAETIGKEIT_MAX_LENGTH;
  }
}
