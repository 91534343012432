import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, LOCALE_ID, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FooterComponent} from './components/footer/footer.component';
import {HeaderComponent} from './components/header/header.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import {ErrorStateMatcher, MAT_DATE_FORMATS} from '@angular/material/core';
import {VpVerifyComponent} from './components/vp-verify/vp-verify.component';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {PdfViewAcceptComponent} from './components/pdf-view-accept/pdf-view-accept.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ButtonLoadingDirective} from './button-loading.directive';
import {VpLandingComponent} from './components/vp-landing/vp-landing.component';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {VpEffects} from './vp-store/vp.effects';
import {metaReducers, reducers} from './vp-store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';
import {ContactCardComponent} from './components/contact-card/contact-card.component';
import {MatIconModule} from '@angular/material/icon';
import {VpCurriculumVitaeComponent} from './components/vp-curriculum-vitae/vp-curriculum-vitae.component';
import {MatRadioModule} from '@angular/material/radio';
import {CvEntryComponent} from './components/cv-entry/cv-entry.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatSelectModule} from '@angular/material/select';
import {DateRangeComponent} from './components/date-range/date-range.component';
import {MatMenuModule} from '@angular/material/menu';
import {MonthInputComponent} from './components/month-input/month-input.component';
import {CvPhotoComponent} from './components/cv-photo/cv-photo.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {CvDocumentsComponent} from './components/cv-documents/cv-documents.component';
import {DndDirective} from './dnd.directive';
import {BytesPipe} from './bytes.pipe';
import {CvPositionComponent} from './components/cv-position/cv-position.component';
import {CvEducationComponent} from './components/cv-education/cv-education.component';
import {CvVolunteeringComponent} from './components/cv-volunteering/cv-volunteering.component';
import {CvSubmitSuccessComponent} from './components/cv-submit-success/cv-submit-success.component';
import {CustomErrorStateMatcher} from './custom-error-state-matcher';
import {CvConfimComponent} from './components/cv-confim/cv-confim.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {QuillModule} from 'ngx-quill';
import {MatQuillModule} from './mat-quill/mat-quill.module';
import {CvDeadlineComponent} from './components/cv-deadline/cv-deadline.component';
import {DeadlineDialogComponent} from './components/deadline-dialog/deadline-dialog.component';
import {Router} from '@angular/router';
import * as Sentry from '@sentry/angular';
import {CvSubmitErrorComponent} from './components/cv-submit-error/cv-submit-error.component';
import {PlatformModule} from '@angular/cdk/platform';
import {MessageDialogComponent} from './components/message-dialog/message-dialog.component';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const APP_DATE_FORMAT = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    VpVerifyComponent,
    PdfViewAcceptComponent,
    ButtonLoadingDirective,
    DndDirective,
    VpLandingComponent,
    ContactCardComponent,
    VpCurriculumVitaeComponent,
    CvEntryComponent,
    DateRangeComponent,
    MonthInputComponent,
    CvPhotoComponent,
    CvDocumentsComponent,
    BytesPipe,
    CvPositionComponent,
    CvEducationComponent,
    CvVolunteeringComponent,
    CvSubmitSuccessComponent,
    CvConfimComponent,
    CvDeadlineComponent,
    DeadlineDialogComponent,
    CvSubmitErrorComponent,
    MessageDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatMomentDateModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSelectModule,
    MatMenuModule,
    MatSnackBarModule,
    MatDialogModule,
    PlatformModule,
    ImageCropperModule,
    QuillModule.forRoot({
      modules: {
        attributes: {
          font: 'palanquin'
        }
      }
    }),
    MatQuillModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    EffectsModule.forRoot([VpEffects]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production})
  ],
  providers: [{
    provide: LOCALE_ID,
    useValue: 'de-DE'
  },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      }
    },
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: false}},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMAT},
    {provide: ErrorStateMatcher, useClass: CustomErrorStateMatcher},
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        logErrors: !environment.production
      }),
    }
  ],
  bootstrap: [AppComponent],
  exports: [
    FooterComponent
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {
  }
}
