import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {ContactData} from '../../service/applicant.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactCardComponent {
  @Input()
  jobTitle: string;
  trustedImageUrlURL: SafeResourceUrl;
  private $contactData: ContactData;

  constructor(readonly sanitizer: DomSanitizer) {
  }

  get contactData(): ContactData {
    return this.$contactData;
  }

  @Input()
  set contactData(value: ContactData) {
    this.$contactData = value;
  }

  @Input()
  set imageUrl(value: string) {
    this.trustedImageUrlURL = this.sanitizer.bypassSecurityTrustResourceUrl(value);

  }
}
