import {Component, Input, OnDestroy, ViewEncapsulation} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {startWith} from 'rxjs/operators';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DateRangeComponent implements OnDestroy {
  min: moment.Moment | null;
  @Input()
  to: UntypedFormControl;
  private fromControl: UntypedFormControl;
  private subscription: Subscription;

  constructor() {
  }

  get from(): UntypedFormControl {
    return this.fromControl;
  }

  @Input()
  set from(value: UntypedFormControl) {
    if (this.fromControl !== value) {
      this.fromControl = value;
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      this.subscription = this.fromControl.valueChanges.pipe(startWith(this.fromControl.value))
        .subscribe(v => this.min = moment(v)); // always wrap into new object
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
