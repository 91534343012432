<app-cv-entry color="lighter" [formGroup]="group">
  <div fxLayout="column" fxLayoutGap="10px" fxFlexFill>
    <mat-form-field>
      <mat-label>Positionsbezeichnung</mat-label>
      <input type="text" formControlName="positionsbezeichnung" matInput required>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Beschäftigungsart</mat-label>
      <mat-select formControlName="beschaeftigungsart" required>
        <mat-option *ngFor="let d of employmentTypes" [value]="d">
          {{ d }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Unternehmen</mat-label>
      <input type="text" formControlName="unternehmen" matInput>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Branche</mat-label>
      <mat-select formControlName="branche" required>
        <mat-option *ngFor="let b of branches" [value]="b">
          {{ b }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Webseite des Unternehmens</mat-label>
      <input type="url" formControlName="url" matInput>
      <mat-error *ngIf="group.get('url').invalid && group.get('url').hasError('invalidUrl')">Keine gültige URL. Sie muss mit <i>http://</i> oder <i>https://</i> beginnen.</mat-error>
    </mat-form-field>
    <app-date-range [from]="group.get('von')" [to]="group.get('bis')"></app-date-range>

    <div fxLayout="row" fxLayoutAlign="end end">
      <button type="button" (click)="remove.emit()" mat-raised-button class="accent-alt">LÖSCHEN</button>
    </div>
  </div>
</app-cv-entry>
