import {Component, Input} from '@angular/core';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UntypedFormControl} from '@angular/forms';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-cv-photo',
  templateUrl: './cv-photo.component.html',
  styleUrls: ['./cv-photo.component.scss']
})
export class CvPhotoComponent {

  imageChangedEvent: Event;
  croppedImage = '';

  @Input()
  control: UntypedFormControl;
  blackWhite = new UntypedFormControl();

  imageFilter = this.blackWhite.valueChanges
    .pipe(map(value => {
      if (value) {
        return 'grayscale(100%)';
      }
      return '';
    }));

  constructor(private snackbar: MatSnackBar) {
    this.blackWhite
      .valueChanges
      .subscribe(value => {
        const ctrlValue = this.control.value;
        this.control.setValue({...ctrlValue, grayscale: value});
      });
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
    const ctrlValue = this.control.value;
    this.control.setValue({...ctrlValue, src: this.croppedImage});
  }

  loadImageFailed(): void {
    this.snackbar.open('Fehler: Bild konnte nicht geladen werden.', 'OK');
  }
}
