import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import * as moment from 'moment';

export interface VerifyResult {
  datenschutzeinwilligung: boolean;
  verschwiegenheitsverpflichtung: boolean;
  anmeldeschluss: Date;
  bewerbungsunterlagenVorhanden: boolean;
  interessent: any;
}

export interface ContactData {
  'email': 'string';
  'person': {
    'nachname': 'string',
    'vorname': 'string'
  };
  'telefon': 'string';
}

@Injectable({
  providedIn: 'root'
})
export class ApplicantService {

  constructor(private http: HttpClient) {
  }

  static formatDateRecursive(obj: any): any {
    return Object.entries(obj)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return [key, value.map(item => ApplicantService.formatDateRecursive(item))];
        }
        // Check if property is date
        if (moment.isMoment(value)) {
          return [key, value.format('YYYY-MM')];
        }
        return [key, value];
    })
      .reduce((previousValue, curr: [string, any]) => {
        previousValue[curr[0]] = curr[1];
        return previousValue;
      }, {});
  }

  private static formatDate(birthdate: Date): string {
    return moment(birthdate).format('YYYY-MM-DD');
  }

  verify(token: string, birthdate: Date): Observable<VerifyResult> {
    const formattedDate = ApplicantService.formatDate(birthdate);
    return this.http.get<VerifyResult>(`/api/vp/verify?token=${token}&geburtsdatum=${formattedDate}`);
  }

  getAWEManagerUrl(token: string, birthdate: Date): string {
    const formattedDate = ApplicantService.formatDate(birthdate);
    return `/api/vp/awe-manager/avatar?token=${token}&geburtsdatum=${formattedDate}`;
  }

  getVPManagerUrl(token: string, birthdate: Date): string {
    const formattedDate = ApplicantService.formatDate(birthdate);
    return `/api/vp/vp-manager/avatar?token=${token}&geburtsdatum=${formattedDate}`;
  }

  accept(pdf: string, token: string, birthdate: Date): Observable<void> {
    const formattedDate = ApplicantService.formatDate(birthdate);
    return this.http.post<void>(`/api/vp/${pdf}?token=${token}&geburtsdatum=${formattedDate}`, null);
  }

  loadAWEManager(token: string, birthdate: Date): Observable<ContactData> {
    const formattedDate = ApplicantService.formatDate(birthdate);
    return this.http.get<ContactData>(`/api/vp/awe-manager?token=${token}&geburtsdatum=${formattedDate}`);
  }

  loadVPManager(token: string, birthdate: Date): Observable<ContactData> {
    const formattedDate = ApplicantService.formatDate(birthdate);
    return this.http.get<ContactData>(`/api/vp/vp-manager?token=${token}&geburtsdatum=${formattedDate}`);
  }

  sendCV(token: string, birthdate: Date, data: { interessent: any, dokumente: any }): Observable<void> {
    const formattedDate = ApplicantService.formatDate(birthdate);
    const formatedData = {
      interessent: {
        ...data.interessent,
        bewerbungsunterlagen: ApplicantService.formatDateRecursive(data.interessent.bewerbungsunterlagen),
        geburtsdatum: ApplicantService.formatDate(data.interessent.geburtsdatum)
      },
      dokumente: data.dokumente
    };
    return this.http.post<void>(`/api/vp?token=${token}&geburtsdatum=${formattedDate}`, formatedData);
  }
}
