import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {State} from '../vp-store';
import {selectVerifyResult} from '../vp-store/vp.selector';
import {map} from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CvDeadlineGuard implements CanActivate {
  constructor(private readonly store: Store<State>, private readonly router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {

    return this.store.select(selectVerifyResult)
      .pipe(map(verifyResult => {
        const token = route.paramMap.get('token');
        if (verifyResult && moment().isAfter(verifyResult.anmeldeschluss)) {
          return this.router.createUrlTree(['/vp', token, 'cv-deadline']);
        }
        return true;
      }));
  }
}
