<div class="jumbotron">
  <div class="mat-headline-2">{{title}}</div>
</div>

<div class="pdf-container" *ngIf="dataURL">
  <object [attr.data]="dataURL" class="pdf-view">
  </object>
  <div class="form">
    <mat-checkbox class="form-checkbox" [formControl]="control">Hiermit bestätige ich, dass ich die {{title}} zur Kenntnis genommen habe und diese akzeptiere.</mat-checkbox>
    <button mat-raised-button type="button" color="accent" (click)="accept()" [loading]="loading | async" [disabled]="(loading | async) || control.invalid">WEITER</button>
    <mat-error *ngIf="error | async" class="form-error">{{title}} konnte nicht bestätigt werden.</mat-error>
  </div>
</div>
