import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {BRANCHES} from '../../branches';

@Component({
  selector: 'app-cv-position',
  templateUrl: './cv-position.component.html',
  styleUrls: ['./cv-position.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CvPositionComponent {

  branches: string[] = BRANCHES;
  employmentTypes: string[] = [
    'Absolvent*in freiwilliges soziales Jahr',
    'Angestellt (Teilzeit)',
    'Angestellt (Vollzeit)',
    'Angestellte*r im öffentlichen Dienst',
    'arbeitssuchend',
    'Auslandaufenthalt',
    'Beamte*r auf Lebenszeit',
    'Beamte*r auf Probe',
    'Beamte*r auf Widerruf',
    'Beamte*r auf Zeit',
    'Berufssoldat*in',
    'Ehrenamtlich tätig',
    'Erasmus-Programm',
    'Freiberuflich',
    'Freiwillig Wehrdienstleistende*r',
    'Gap Year',
    'Geringfügig Beschäftigte*r',
    'Hausfrau/Hausmann',
    'Leitende*r Angestellte*r',
    'Praktikant*in',
    'Selbstständige*r',
    'Sonstiges',
    'Zeitsoldat*in',
  ];

  @Input()
  group: UntypedFormGroup;

  @Output()
  remove = new EventEmitter<void>();

  constructor() { }

}
