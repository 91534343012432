<app-cv-entry title="Foto" color="light">
  <input #fileInput type="file" (change)="imageChangedEvent = $event" hidden/>
  <button type="button" mat-raised-button color="accent" (click)="fileInput.click()" style="margin-bottom: 25px">BILD
    AUSWÄHLEN
  </button>
  <div fxLayout="row wrap" fxLayoutAlign="center start" fxLayoutGap="42px" fxFlexFill>
    <div fxLayout="column" fxFlex="0 0 320px">
      <h3>Zuschneiden</h3>
      <image-cropper
        fxFlex="0 0 320px"
        class="cropper"
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="1 / 1"
        [resizeToWidth]="640"
        [onlyScaleDown]="true"
        [roundCropper]="true"
        format="jpeg"
        (imageCropped)="imageCropped($event)"
        (loadImageFailed)="loadImageFailed()"></image-cropper>
      <mat-slide-toggle style="margin-top: 16px" [formControl]="blackWhite">Schwarz-Weiß</mat-slide-toggle>
    </div>
    <div fxLayout="column" fxFlex="0 0 320px">
      <h3>Vorschau</h3>
      <div class="preview-container">
        <img class="preview" [src]="croppedImage" [style.filter]="imageFilter | async"/>
      </div>
    </div>
  </div>
</app-cv-entry>
