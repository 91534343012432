import {createReducer, on} from '@ngrx/store';
import {
  accept,
  acceptError,
  acceptSuccess,
  loadAWEManager,
  loadAWEManagerError,
  loadAWEManagerSuccess,
  loadVPManager,
  loadVPManagerError,
  loadVPManagerSuccess,
  sendCV,
  sendCVError,
  sendCVSuccess,
  verifyVP,
  verifyVPError,
  verifyVPSuccess
} from './vp.actions';
import {initialState} from "./vp.state";

export const reducer = createReducer(
  initialState,
  on(verifyVP, (state, _) => ({...state, loading: true, error: null})),
  on(sendCV, (state, _) => ({...state, loading: true, error: null})),
  on(loadAWEManager, (state, _) => ({...state, loading: true, error: null})),
  on(loadVPManager, (state, _) => ({...state, loading: true, error: null})),
  on(loadVPManagerSuccess, (state, action) => ({
    ...state,
    loading: false,
    error: null,
    vpManager: action.contactData
  })),
  on(loadAWEManagerSuccess, (state, action) => ({
    ...state,
    loading: false,
    error: null,
    aweManager: action.contactData
  })),
  on(accept, (state, _) => ({...state, loading: true, error: null})),
  on(verifyVPSuccess, (state, action) => ({
    ...state,
    loading: false,
    error: null,
    token: action.token,
    birthdate: action.birthdate,
    verifyResult: action.verifyResult
  })),

  on(acceptSuccess, (state, action) => ({
    ...state,
    error: null,
    loading: false,
    verifyResult: {...state.verifyResult, [action.stateProperty]: true},
  })),
  on(verifyVPError, ((state, _) => ({...state, error: verifyVPError, loading: false}))),
  on(acceptError, ((state, _) => ({...state, error: acceptError, loading: false}))),
  on(sendCVError, ((state, _) => ({...state, error: sendCVError, loading: false}))),
  on(sendCVSuccess, ((state, action) => ({
      ...state,
      verifyResult: {...state.verifyResult, bewerbungsunterlagenVorhanden: true},
      birthdate: action.birthdate,
      error: null, loading: false
    })
  )),
  on(loadVPManagerError, ((state, _) => ({...state, error: loadVPManagerError, loading: false}))),
  on(loadAWEManagerError, ((state, _) => ({...state, error: loadAWEManagerError, loading: false})))
);
