import {ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-cv-entry',
  templateUrl: './cv-entry.component.html',
  styleUrls: ['./cv-entry.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CvEntryComponent {
  @Input()
  title: string;

  /**
   * This is the input that sets the class based on the enum string.
   * It uses HostBinding to class to auto attach the string value
   * to the elements class attribute.
   */
  @Input()
  @HostBinding('class')
  color: 'ligther' | 'light' | 'primary' | 'green' = 'primary';

  constructor() { }

}
