import {ChangeDetectorRef, Component, Input, OnDestroy} from '@angular/core';
import {AbstractControl, UntypedFormControl, ValidatorFn} from '@angular/forms';
import {forkJoin, fromEvent, Observable, Subject} from 'rxjs';
import {map, pluck, takeUntil} from 'rxjs/operators';

const readFile = (blob) => new Observable<string>(obs => {
  if (!(blob instanceof Blob)) {
    obs.error(new Error('`blob` must be an instance of File or Blob.'));
    return;
  }

  const reader = new FileReader();

  reader.onerror = err => obs.error(err);
  reader.onabort = err => obs.error(err);
  reader.onload = () => obs.next(reader.result as string);
  reader.onloadend = () => obs.complete();

  return reader.readAsDataURL(blob);
});

const base64Size = (data: string): number => {
  const y = data.endsWith('==') ? 2 : 1;
  return (data.length * (3 / 4)) - y;
};

export function fileSizeValidator(maxBytes: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const documents = control.value;
    if (documents && Array.isArray(documents)) {
      const actualSize = documents.reduce((prev, cur) => {
        return prev + base64Size(cur.data);
      }, 0);
      return actualSize > maxBytes ? {fileSize: {max: maxBytes, actual: actualSize}} : null;
    }
    return null;
  };
}

@Component({
  selector: 'app-cv-documents',
  templateUrl: './cv-documents.component.html',
  styleUrls: ['./cv-documents.component.scss']
})
export class CvDocumentsComponent implements OnDestroy {

  @Input()
  documents: UntypedFormControl;
  private destroy = new Subject<void>();
  processingFiles = false;
  result: string;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  onFileChange(event: Event): void {
    const fileList: FileList = (event.target as HTMLInputElement).files;
    this.onFilesDropped(fileList);
  }

  onFilesDropped(files: FileList): void {
    if (files.length) {
      this.processingFiles = true;
    }
    const fileLoads = Array.from(files)
      .map(file => {
        return readFile(file)
          .pipe(map(content => ({
            data: content.substring(content.indexOf(',') + 1, content.length),
            contentType: file.type,
            filename: file.name
          })));
      });
    forkJoin(fileLoads)
      .pipe(takeUntil(this.destroy))
      .subscribe(result => {
        const ctrlValue = this.documents.value;
        this.documents.setValue([...ctrlValue, ...result]);
        setTimeout(() => {
          this.processingFiles = false;
          this.changeDetectorRef.markForCheck();
        }, 500);
        this.changeDetectorRef.markForCheck();
      });
  }

  fileToBase64(fileReader: FileReader, fileToRead: File): Observable<any> {
    fileReader.readAsDataURL(fileToRead);
    return fromEvent(fileReader, 'loadend')
      .pipe(pluck('result'));
  }

  size(data: string): number {
    return base64Size(data);
  }

  remove(i: number): void {
    const ctrlValue = this.documents.value;
    ctrlValue.splice(i, 1);
    this.documents.setValue(ctrlValue);
  }
}
