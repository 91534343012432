import {ContactData, VerifyResult} from "../service/applicant.service";
import {TypedAction} from "@ngrx/store/src/models";

export interface State {
  token: string | null;
  birthdate: Date | null;
  verifyResult: VerifyResult | null;
  loading: boolean;
  error: TypedAction<any>;
  vpManager: ContactData | null;
  aweManager: ContactData | null;
}

export const initialState: State = {
  token: null,
  birthdate: null,
  verifyResult: null,
  loading: false,
  error: null,
  vpManager: null,
  aweManager: null
};
