import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'app-cv-submit-error',
  templateUrl: './cv-submit-error.component.html',
  styleUrls: ['./cv-submit-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CvSubmitErrorComponent {
}
