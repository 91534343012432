import {createFeatureSelector, createSelector} from "@ngrx/store";
import {State} from "./vp.state";


export const featureKey = 'vp';

export const selectState = createFeatureSelector<State>(featureKey);

export const isLoading = createSelector(selectState, s1 => s1.loading);
export const hasError = createSelector(selectState, s1 => s1.error !== null);
export const selectError = createSelector(selectState, s1 => s1.error);

export const selectToken = createSelector(selectState, s1 => s1.token);
export const selectVerifyResult = createSelector(selectState, s1 => s1.verifyResult);
export const selectDeadline = createSelector(selectVerifyResult, r => r ? r.anmeldeschluss : null);
export const selectApplicant = createSelector(selectVerifyResult, r => r.interessent);

export const selectAWEManager = createSelector(selectState, s => s.aweManager);
export const selectVPManager = createSelector(selectState, s => s.vpManager);
