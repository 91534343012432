<app-cv-entry color="lighter" [formGroup]="group">
  <div fxLayout="column" fxLayoutGap="16px" fxFlexFill>
    <mat-form-field>
      <mat-label>Institution</mat-label>
      <input type="text" formControlName="institution" matInput required>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Tätigkeit</mat-label>
      <textarea matNativeControl formControlName="taetigkeit" rows="5" placeholder="Tätigkeit"></textarea>
      <mat-hint [class.mat-error]="group.get('taetigkeit').errors && group.get('taetigkeit').errors.maxlength">{{taetigkeitCount}}/{{taetigkeitMaxLength}}</mat-hint>
    </mat-form-field>
    <app-date-range [from]="group.get('von')" [to]="group.get('bis')"></app-date-range>

    <div fxLayout="row" fxLayoutAlign="end end">
      <button type="button" (click)="remove.emit()" mat-raised-button class="accent-alt">LÖSCHEN</button>
    </div>
  </div>
</app-cv-entry>
