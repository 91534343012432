<div mat-dialog-content>
  <p>Der Anmeldeschluss für das AC1 ist am {{data.anmeldeschluss | date:'dd.MM.yyyy'}} um {{data.anmeldeschluss | date:'HH:mm'}} Uhr abgelaufen.</p>
  <p>Bitte wende dich an {{data.manager.vorname}} {{data.manager.nachname}}, um zu prüfen,
    ob ein anderes Datum in Frage kommt. Sobald du für einen anderen Termin vorgemerkt bist,
    erhälst du von uns eine E-mail mit dem neuen Termin.
    Du erreichst das Bewerbungsportal zum Einreichen deiner Bewerbung weiterhin über deinen persönlichen Link.</p>
</div>

<div mat-dialog-actions align="end">
  <button mat-raised-button type="button" color="primary" mat-dialog-close>Ok</button>
</div>
