<div class="jumbotron">
  <div class="mat-headline-2">Herzlich willkommen bei TauRes!</div>
  <video controls autoplay>
    <source src="https://storage.de.cloud.ovh.net/v1/AUTH_e1126f2247c74999b4e099c82e89c925/public-content/videos/aktivierungstool.mp4"
            type="video/mp4">
    Dein Browser kann keine Videos anzeigen.
  </video>
  <p>Mit deinem Entschluss, uns näher kennenzulernen, ist der erste Schritt zu einer Teilnahme an unserem
    Assessment-Center (AC1) getan. Deine Eintrittskarte zu dem AC1 verbirgt sich hinter dem Menüpunkt "Deine
    Bewerbung“.</p>
  <p>Mit dem Bewerbungsformular hast du die Möglichkeit, uns mehr Informationen über deine Ausbildung, deine bisherigen
    beruflichen Erfahrungen, deine privaten Interessen und natürlich über deine Motivation für eine
    Vertriebspartnerschaft mitzuteilen. Je besser wir uns kennenlernen, desto klarer und einfacher ist die Entscheidung
    für eine langfristige Zusammenarbeit.</p>
  <p>Deshalb laden wir dich herzlich dazu ein, uns schon vorab so viele Fragen wie nur möglich zu stellen. Wir freuen
    uns sehr, dich näher und persönlich kennenzulernen!</p>
</div>


<div class="link-grid">
  <div class="link-card">
    <img src="../../../assets/bewerbung.jpg">
  </div>
  <div class="link-card">
    <div class="link-card-content">
      <h1>Deine Bewerbung</h1>
      <a mat-flat-button color="accent" [routerLink]="['/vp', (token | async), 'cv']">MEHR ERFAHREN</a>
    </div>
  </div>
</div>

<div class="personal-contact">
  <div class="mat-headline-2">Deine Ansprechpartner</div>
  <div class="link-grid">
    <app-contact-card *ngIf="vpManager | async as vp" [contactData]="vp" jobTitle="Manager und Gesellschafter der TauRes GmbH" [imageUrl]="vpMangerUrl | async"></app-contact-card>
    <app-contact-card *ngIf="aweManager | async as awe" [contactData]="awe" jobTitle="Leiter Vertriebspartnerprozess" [imageUrl]="aweMangerUrl | async"> </app-contact-card>
  </div>
</div>

