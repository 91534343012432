import {createAction, props} from '@ngrx/store';
import {ContactData, VerifyResult} from '../service/applicant.service';

export const verifyVP = createAction('[vp] verify',  props<{ birthdate: Date }>());
export const verifyVPSuccess = createAction('[vp] verify success',  props<{ token: string; birthdate: Date, verifyResult: VerifyResult}>());
export const verifyVPError = createAction('[vp] verify error');

export const accept = createAction('[vp] accept', props<{ document: string, stateProperty: string }>());
export const acceptSuccess = createAction('[vp] accept success', props<{ stateProperty: string }>());
export const acceptError = createAction('[vp] accept error');

export const loadAWEManager = createAction('[vp] load awe manager');
export const loadAWEManagerSuccess = createAction('[vp] load awe manager success', props<{ contactData: ContactData}>());
export const loadAWEManagerError = createAction('[vp] load awe manager error');

export const loadVPManager = createAction('[vp] load vp manager');
export const loadVPManagerSuccess = createAction('[vp] load vp manager success', props<{ contactData: ContactData}>());
export const loadVPManagerError = createAction('[vp] load vp manager error');

export const sendCV = createAction('[vp] send CV', props<{ data: any}>());
export const sendCVSuccess = createAction('[vp] send CV success', props<{ birthdate: Date }>());
export const sendCVError = createAction('[vp] send CV error', props<{birthdate: Date}>());
