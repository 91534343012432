import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {loadVPManager} from '../../vp-store/vp.actions';
import {Store} from '@ngrx/store';
import {State} from '../../vp-store';
import {selectDeadline, selectVPManager} from '../../vp-store/vp.selector';

@Component({
  selector: 'app-cv-deadline',
  templateUrl: './cv-deadline.component.html',
  styleUrls: ['./cv-deadline.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CvDeadlineComponent implements OnInit {

  vpManager = this.store.select(selectVPManager);
  deadline = this.store.select(selectDeadline);

  constructor(private store: Store<State>) {
  }

  ngOnInit(): void {
    this.store.dispatch(loadVPManager());
  }

}
