import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'app-cv-confim',
  templateUrl: './cv-confim.component.html',
  styleUrls: ['./cv-confim.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CvConfimComponent {

  constructor() { }

}
