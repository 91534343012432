import {ChangeDetectionStrategy, Component} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {hasError, isLoading} from '../../vp-store/vp.selector';
import {State} from '../../vp-store';
import {verifyVP} from '../../vp-store/vp.actions';

@Component({
  selector: 'app-vp-verify',
  templateUrl: './vp-verify.component.html',
  styleUrls: ['./vp-verify.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VpVerifyComponent {
  form = this.fb.group({
    dateOfBirth: [null, [Validators.required]]
  });
  error = this.store.select(hasError);
  loading = this.store.select(isLoading);

  constructor(private store: Store<State>, private fb: UntypedFormBuilder) {
  }

  verify(): void {
    this.store.dispatch(verifyVP({birthdate: this.form.get('dateOfBirth').value}));
  }
}
