import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, map, tap, withLatestFrom} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {ApplicantService} from '../service/applicant.service';
import {of} from 'rxjs';
import {
  accept,
  acceptError,
  acceptSuccess,
  loadAWEManager,
  loadAWEManagerError,
  loadAWEManagerSuccess,
  loadVPManager,
  loadVPManagerError,
  loadVPManagerSuccess,
  sendCV,
  sendCVError,
  sendCVSuccess,
  verifyVP,
  verifyVPError,
  verifyVPSuccess
} from './vp.actions';
import {selectQueryParam, selectRouteParam, State} from './index';
import {selectDeadline, selectState, selectVPManager} from './vp.selector';
import {Router} from '@angular/router';
import * as Sentry from '@sentry/angular';
import {MatDialog} from '@angular/material/dialog';
import {CvSubmitErrorComponent} from '../components/cv-submit-error/cv-submit-error.component';
import {MessageDialogComponent} from '../components/message-dialog/message-dialog.component';
import {DeadlineDialogComponent} from '../components/deadline-dialog/deadline-dialog.component';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class VpEffects {
  verifyVPApplicant$ = createEffect(() => this.actions$.pipe(
    ofType(verifyVP),
    withLatestFrom(this.store.select(selectRouteParam('token'))),
    exhaustMap(([action, token]) => {
        return this.applicantService.verify(token, action.birthdate)
          .pipe(
            map(result => verifyVPSuccess({token, birthdate: action.birthdate, verifyResult: result})),
            catchError(err => {
              Sentry.setUser({
                id: token
              });
              Sentry.captureEvent(err);
              return of(verifyVPError());
            })
          );
      }
    )));

  /**
   * Goto next url or to landing page if no next url present
   */
  defineSentryScope$ = createEffect(() => this.actions$.pipe(
    ofType(verifyVPSuccess),
    withLatestFrom(this.store.select(selectState)),
    tap(([action, state]) => {
      Sentry.setUser({
        id: state.token
      });
    })
  ), {dispatch: false});

  /**
   * Goto next url or to landing page if no next url present
   */
  gotoNext$ = createEffect(() => this.actions$.pipe(
    ofType(verifyVPSuccess, acceptSuccess),
    withLatestFrom(this.store.select(selectRouteParam('token')), this.store.select(selectQueryParam('next'))),
    map(([data, token, next]) => next ? [next] : ['/vp', token, 'landing']),
    tap(commands => this.router.navigate(commands, {replaceUrl: true}))
    ), {dispatch: false});

  gotoSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(sendCVSuccess),
    withLatestFrom(this.store.select(selectRouteParam('token'))),
    map(([_, token]) => ['/vp', token, 'cv-submitted']),
    tap(commands => this.router.navigate(commands, {replaceUrl: true}))
  ), {dispatch: false});

  acceptLegalDocument$ = createEffect(() => this.actions$.pipe(
    ofType(accept),
    withLatestFrom(this.store.select(selectState)),
    exhaustMap(([action, vpState]) => {
        return this.applicantService.accept(action.document, vpState.token, vpState.birthdate)
          .pipe(
            map(_ => acceptSuccess({stateProperty: action.stateProperty})),
            catchError(err => {
              Sentry.captureEvent(err);
              return of(acceptError());
            })
          );
      }
    )));

  loadAWEManager$ = createEffect(() => this.actions$.pipe(
    ofType(loadAWEManager),
    withLatestFrom(this.store.select(selectState)),
    exhaustMap(([_, vpState]) => {
      return this.applicantService.loadAWEManager(vpState.token, vpState.birthdate)
          .pipe(
            map(result => loadAWEManagerSuccess({contactData: result})),
            catchError(err => {
              Sentry.captureEvent(err);
              return of(loadAWEManagerError());
            })
          );
      }
    )));

  loadVPManager$ = createEffect(() => this.actions$.pipe(
    ofType(loadVPManager),
    withLatestFrom(this.store.select(selectState)),
    exhaustMap(([_, vpState]) => {
        return this.applicantService.loadVPManager(vpState.token, vpState.birthdate)
          .pipe(
            map(result => loadVPManagerSuccess({contactData: result})),
            catchError(err => {
              Sentry.captureEvent(err);
              return of(loadVPManagerError());
            })
          );
      }
    )));

  sendCV$ = createEffect(() => this.actions$.pipe(
    ofType(sendCV),
    withLatestFrom(this.store.select(selectState)),
    exhaustMap(([action, vpState]) => {
        return this.applicantService.sendCV(vpState.token, vpState.birthdate, action.data)
          .pipe(
            map(_ => sendCVSuccess({birthdate: action.data.interessent.geburtsdatum})),
            catchError(err => {
              Sentry.captureEvent(err);
              return of(sendCVError({birthdate: action.data.interessent.geburtsdatum}));
            })
          );
      }
    )));

  showDetailedSendError$ = createEffect(() => this.actions$.pipe(
    ofType(sendCVError),
    withLatestFrom(this.store.select(selectRouteParam('token')), this.store.select(selectDeadline), this.store.select(selectVPManager)),
    exhaustMap(([action, token, deadline, manager]) => {
      return this.applicantService.verify(token, action.birthdate).pipe(
        map(verifyResult => {
          if (!verifyResult.anmeldeschluss) {
            return this.dialog.open(MessageDialogComponent, {
              data: {
                message: 'Deine Anmeldung zum Accessment-Center wurde gelöscht. Bitte wende dich an ' +
                  manager.person.vorname + ' ' + manager.person.nachname + ', um zu prüfen, ob ein anderes Datum in Frage kommt.' +
                  ' Sobald du für einen anderen Termin vorgemerkt bist, erhältst du von uns eine E-mail mit dem neuen Termin.' +
                  ' Du erreichst das Bewerbungsportal zum Einreichen deiner Bewerbung weiterhin über deinen persönlichen Link.'
              }
            });
          } else if (deadline && !moment(verifyResult.anmeldeschluss).isSame(deadline)) {
            return this.dialog.open(DeadlineDialogComponent, {
              data: {
                anmeldeschluss: verifyResult.anmeldeschluss,
                manager: manager.person
              }
            });
          }
          return this.dialog.open(CvSubmitErrorComponent);
        })
      );
    })
  ), {dispatch: false});

  constructor(private actions$: Actions,
              private router: Router,
              private store: Store<State>,
              private applicantService: ApplicantService,
              private dialog: MatDialog) {
  }
}
