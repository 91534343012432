<app-cv-entry [title]="title(group)" color="light" [formGroup]="group">
  <div fxLayout="column" fxLayoutGap="10px" fxFlexFill>
    <ng-container [ngSwitch]="group.get('typ').value">
      <div *ngSwitchCase="'schule'" fxLayout="column" fxLayoutGap="16px" fxFlexFill>
        <h3>Bitte lediglich den höchsten (bisher) erreichten Schulabschluss angeben.</h3>
        <mat-form-field>
          <mat-label>Name der Schule</mat-label>
          <input type="text" formControlName="name" matInput required>
        </mat-form-field>

        <div fxLayout="row" fxLayoutGap="16px">
          <mat-form-field fxFlex="60%">
            <mat-label>(Angestrebter) Abschluss</mat-label>
            <mat-select formControlName="abschluss" (selectionChange)="schoolGradChange($event)">
              <mat-option *ngFor="let grad of graduations" [value]="grad.value">
                {{ grad.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <app-month-input fxFlex="40%"
                           [min]="minDate"
                           [max]="maxDate"
                           label="Beendigung der Schule"
                           hint="Leer lassen, wenn noch nicht abgeschlossen"
                           [control]="group.get('ende')"></app-month-input>
        </div>
      </div>

      <div *ngSwitchCase="'studium'" fxLayout="column" fxLayoutGap="10px" fxFlexFill>
        <mat-form-field>
          <mat-label>Hochschule</mat-label>
          <input type="text" formControlName="hochschule" matInput required>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Fachrichtung</mat-label>
          <input type="text" formControlName="fachrichtung" matInput>
        </mat-form-field>
        <div fxLayout="row" fxLayoutGap="16px">
          <mat-form-field fxFlex="50%">
            <mat-label>(Angestrebter) Abschluss</mat-label>
            <mat-select formControlName="abschluss" (selectionChange)="diplomaChange($event)">
              <mat-option *ngFor="let d of diploma" [value]="d.value">
                {{ d.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="50%">
            <mat-label>Anderer Abschluss</mat-label>
            <input type="text" formControlName="andererAbschluss" matInput>
          </mat-form-field>
        </div>
        <app-date-range [from]="group.get('von')" [to]="group.get('bis')"></app-date-range>

      </div>

      <div *ngSwitchCase="'lehre'" fxLayout="column" fxLayoutGap="10px" fxFlexFill>
        <mat-form-field>
          <mat-label>Unternehmen</mat-label>
          <input type="text" formControlName="unternehmen" matInput required>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Branche</mat-label>
          <mat-select formControlName="branche">
            <mat-option *ngFor="let b of branches" [value]="b">
              {{ b }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>(Angestrebter) Abschluss</mat-label>
          <input type="text" formControlName="abschluss" matInput>
        </mat-form-field>
        <app-date-range [from]="group.get('von')" [to]="group.get('bis')"></app-date-range>
      </div>
    </ng-container>

    <div fxLayout="row" fxLayoutAlign="end end">
      <button type="button" (click)="remove.emit()" mat-raised-button class="accent-alt">LÖSCHEN</button>
    </div>
  </div>
</app-cv-entry>

