<div class="jumbotron">
  <div class="mat-headline-2">Herzlich willkommen!</div>
  <p>Deine Karriere bei TauRes startet gleich nach der Verifizierung durch dein Geburtsdatum. Dann bitten wir dich nur
    noch, die Datenschutzeinwilligung und die Verschwiegenheitsvereinbarung aufmerksam zu lesen, bevor du auf unser
    Karriereportal weitergeleitet wirst.</p>
  <p>Wir freuen uns auf dich!</p>
</div>
<div class="form-container">
  <form [formGroup]="form"
        class="form"
        (submit)="verify()"
        novalidate>
    <div class="mat-headline-5">Verifizierung</div>
    <mat-form-field class="form-item">
      <mat-label>Bitte gib hier dein Geburtsdatum ein</mat-label>
      <input [matDatepicker]="birthdate" matInput placeholder="Geburtsdatum" required formControlName="dateOfBirth">
      <mat-datepicker-toggle matSuffix [for]="birthdate" ></mat-datepicker-toggle>
      <mat-datepicker #birthdate></mat-datepicker>
    </mat-form-field>
    <button mat-raised-button type="button" color="accent" (click)="verify()" [disabled]="(loading | async) || form.invalid" [loading]="loading | async">WEITER</button>
    <mat-error *ngIf="error | async" class="form-error">Daten konnten nicht verifiziert werden.</mat-error>
  </form>
</div>

