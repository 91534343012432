<app-header></app-header>
<main>
  <div class="header-image-container">
  </div>
  <div class="main-container">
    <router-outlet></router-outlet>
  </div>
</main>

<app-footer></app-footer>
