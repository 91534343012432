import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {BRANCHES} from '../../branches';
import {MatSelectChange} from '@angular/material/select';
import * as moment from 'moment';

enum AbschlussTyp {
  KEINER = 'KEINER',
  HAUPTSCHULE = 'HAUPTSCHULE',
  REALSCHULE = 'REALSCHULE',
  ABITUR = 'ABITUR',
  BACHELOR = 'BACHELOR',
  MASTER = 'MASTER',
  DIPLOM = 'DIPLOM',
  MEISTER = 'MEISTER',
  FACHHOCHSCHULREIFE = 'FACHHOCHSCHULREIFE',
  ABGESCHLOSSENE_BERUFSAUSBILDUNG = 'ABGESCHLOSSENE_BERUFSAUSBILDUNG'
}

export function schuleRequiredValidator(control: AbstractControl): ValidationErrors | null {
  if (control instanceof UntypedFormArray) {
    const schulen = control.controls.filter(ausb => ausb.get('typ').value === 'schule');
    return schulen.length > 0 ? null : {schuleRequired: true};
  }
  return null;
}

@Component({
  selector: 'app-cv-education',
  templateUrl: './cv-education.component.html',
  styleUrls: ['./cv-education.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CvEducationComponent {
  graduations: { value: string, typ: AbschlussTyp }[] = [
    {value: 'Hochschulreife', typ: AbschlussTyp.ABITUR},
    {value: 'Fachhochschulreife', typ: AbschlussTyp.FACHHOCHSCHULREIFE},
    {value: 'Fachgebundene Hochschulreife', typ: AbschlussTyp.FACHHOCHSCHULREIFE},
    {value: 'Schulischer Teil der Fachhochschulreife', typ: AbschlussTyp.FACHHOCHSCHULREIFE},
    {value: 'Praktischer Teil der Fachhochschulreife', typ: AbschlussTyp.FACHHOCHSCHULREIFE},
    {value: 'Realschulabschluss', typ: AbschlussTyp.REALSCHULE},
    {value: 'Erweiterter Hauptschulabschluss', typ: AbschlussTyp.HAUPTSCHULE},
    {value: 'Hauptschulabschluss', typ: AbschlussTyp.HAUPTSCHULE},
    {value: 'Noch in schulischer Ausbildung', typ: AbschlussTyp.KEINER},
    {value: 'Ohne allgemeinen Schulabschluss', typ: AbschlussTyp.KEINER},
  ];
  diploma: { value: string, typ: AbschlussTyp }[] = [
    {value: 'Master of Business Administration', typ: AbschlussTyp.MASTER},
    {value: 'Master of Engineering', typ: AbschlussTyp.MASTER},
    {value: 'Master of Laws', typ: AbschlussTyp.MASTER},
    {value: 'Master of Science', typ: AbschlussTyp.MASTER},
    {value: 'Master of Arts', typ: AbschlussTyp.MASTER},
    {value: 'Bachelor of Engineering', typ: AbschlussTyp.BACHELOR},
    {value: 'Bachelor of Education', typ: AbschlussTyp.BACHELOR},
    {value: 'Bachelor of Laws', typ: AbschlussTyp.BACHELOR},
    {value: 'Bachelor of Science', typ: AbschlussTyp.BACHELOR},
    {value: 'Bachelor of Business Administration', typ: AbschlussTyp.BACHELOR},
    {value: 'Bachelor of Arts', typ: AbschlussTyp.BACHELOR},
    {value: 'abgebrochen', typ: AbschlussTyp.KEINER},
  ];
  branches: string[] = BRANCHES;
  @Input()
  group: UntypedFormGroup;

  @Output()
  remove = new EventEmitter<void>();

  minDate = moment('1970-01-01');
  maxDate = moment('2040-01-01');

  constructor() {
  }

  title(group: UntypedFormGroup): string {
    const typ = group.get('typ').value;
    switch (typ) {
      case 'schule':
        return '';
      case 'studium':
        return 'Studium';
      case 'lehre':
        return 'Lehre/Berufsausbildung';
    }
  }

  schoolGradChange($event: MatSelectChange): void {
    const typ = this.graduations.find(e => e.value === $event.value).typ;
    this.group.get('abschlussTyp').setValue(typ);
  }

  diplomaChange($event: MatSelectChange): void {
    const typ = this.diploma.find(e => e.value === $event.value).typ;
    this.group.get('abschlussTyp').setValue(typ);
  }
}
