import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'app-cv-submit-success',
  templateUrl: './cv-submit-success.component.html',
  styleUrls: ['./cv-submit-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CvSubmitSuccessComponent {
  constructor() {
  }

}
