<div class="jumbotron">
  <div class="mat-headline-2">Deine Bewerbung</div>
  <p>Wir freuen uns auf deine Bewerbung.</p>
  <p>Bitte beachte den <strong>Anmeldeschluss</strong> am <strong>{{anmeldeschluss | date:'dd.MM.yyyy, HH:mm'}} Uhr</strong>.</p>
  <p>Da eine Zwischenspeicherung der Daten aus datenschutzrechtlichen Gründen nicht möglich ist, empfehlen wir dir,
    alle Abschlusszeugnisse im PDF-Format bereitzuhalten und den Text für deine Motivation ggf. vorher vorzubereiten.</p>
</div>
<form [formGroup]="form"
      class="form-container"
      (submit)="onSubmit()"
      novalidate>
  <app-cv-entry title="Persönliche Daten" color="light">
    <div fxLayout="column" fxLayoutGap="10px" formGroupName="interessent" fxFlexFill>
      <mat-radio-group formControlName="geschlecht" required>
        <mat-radio-button value="w">
          weiblich
        </mat-radio-button>
        <mat-radio-button value="m">
          männlich
        </mat-radio-button>
        <mat-radio-button value="d">
          divers
        </mat-radio-button>
      </mat-radio-group>

      <div fxLayout="row" fxLayoutGap="16px">
        <mat-form-field fxFlex="25%" class="-mb-20">
          <mat-label>Vollständiger Vorname</mat-label>
          <input type="text" formControlName="vorname" matInput required>
        </mat-form-field>

        <mat-form-field fxFlex="75%" class="-mb-20">
          <mat-label>Vollständiger Nachname</mat-label>
          <input type="text" formControlName="nachname" matInput required>
        </mat-form-field>
      </div>

      <mat-hint style="margin-bottom: 8px;font-size: 75%;line-height: initial;padding: 0 10.5px; color: #0009;">
        Um mögliche Dubletten zu vermeiden, beachte bitte die korrekte und vollständige Eingabe deiner Daten,
        ggf. unter Berücksichtigung eines zweiten Vornamens, analog zu deinem Personalausweis.</mat-hint>

      <mat-form-field>
        <mat-label>Rufname</mat-label>
        <input type="text" formControlName="rufname" matInput>
        <mat-hint style="color: #0009;">Diese Angabe dient als zusätzliche Info hinsichtlich der Erstellung von Namensschildern.</mat-hint>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Geburtsname</mat-label>
        <input type="text" formControlName="geburtsname" matInput>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Geburtsdatum</mat-label>
        <input [matDatepicker]="birthdate" placeholder="Geburtsdatum" formControlName="geburtsdatum" matInput required>
        <mat-datepicker-toggle matSuffix [for]="birthdate"></mat-datepicker-toggle>
        <mat-datepicker #birthdate></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Nationalität</mat-label>
        <mat-select formControlName="nationalitaet" required>
          <mat-option *ngFor="let country of sortedCountries" [value]="country.code">
            {{ country.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </app-cv-entry>

  <app-cv-photo [control]="form.get('bewerbungsunterlagen.bild')"></app-cv-photo>

  <app-cv-entry title="Kontaktdaten" color="lighter">
    <div fxLayout="column" fxLayoutGap="10px" formGroupName="interessent" fxFlexFill>
      <div fxLayout="row" fxLayoutGap="16px">
        <mat-form-field fxFlex="80%">
          <mat-label>Straße</mat-label>
          <input type="text" formControlName="strasse" matInput required>
        </mat-form-field>

        <mat-form-field fxFlex="20%">
          <mat-label>Hausnummer</mat-label>
          <input type="text" formControlName="hausnummer" matInput required>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="16px">
        <mat-form-field fxFlex="20%">
          <mat-label>PLZ</mat-label>
          <input type="text" formControlName="plz" matInput required>
        </mat-form-field>
        <mat-form-field fxFlex="80%">
          <mat-label>Ort</mat-label>
          <input type="text" formControlName="stadt" matInput required>
        </mat-form-field>
      </div>

      <mat-form-field>
        <mat-label>Telefon Festnetz</mat-label>
        <input type="text" formControlName="telefonPrivat" matInput>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Telefon mobil</mat-label>
        <input type="text" formControlName="telefonMobil" matInput required>
      </mat-form-field>

      <mat-form-field>
        <mat-label>E-Mail</mat-label>
        <input type="email" formControlName="email" matInput required>
      </mat-form-field>
    </div>
  </app-cv-entry>

  <app-cv-entry color="green" title="Hinweis">
    <p>Bitte erfasse hier möglichst alle Stationen im Rahmen deines Lebenslaufes. Nur so ist es uns möglich, ein
      umfassendes Bild von dir zu erhalten.</p>
    <p>Bewerbungen die unvollständig eingereicht werden, können nicht bearbeitet werden. Deinen Lebenslauf benötigen
      wir <strong>nicht</strong> im PDF-Format.</p>
    <p>Etwaige Auslandaufenthalte kannst du unter „Werdegang“ eintragen.</p>
  </app-cv-entry>

  <ng-container formGroupName="bewerbungsunterlagen">
    <div class="form-array" formArrayName="berufserfahrung">
      <div class="mat-headline-3">Werdegang</div>
      <app-cv-position *ngFor="let position of berufserfahrung.controls; let i=index"
                       [group]="position"
                       (remove)="removeBerufserfahrung(i)"
                       class="form-array-item"></app-cv-position>

      <button type="button" mat-raised-button color="accent" (click)="addBerufserfahrung()">POSITION HINZUFÜGEN</button>
    </div>

    <div class="form-array" formArrayName="ausbildung">
      <div class="mat-headline-3">Ausbildung</div>
      <ng-container *ngFor="let ausb of ausbildung.controls; let i=index">
        <app-cv-education *ngIf="!isSchule(ausb)"
                          [group]="ausb"
                          class="form-array-item"
                          (remove)="removeAusbildung(i)"></app-cv-education>
      </ng-container>
      <mat-error *ngIf="form.invalid && form.touched && form.get('bewerbungsunterlagen.ausbildung').errors && form.get('bewerbungsunterlagen.ausbildung').errors.ausbildungRequired">Bitte mindestends einen Eintrag zu Ausbildung hinzufügen.</mat-error>
      <button type="button" mat-raised-button color="accent" [matMenuTriggerFor]="educationTypes">AUSBILDUNG HINZUFÜGEN
      </button>
      <mat-menu #educationTypes="matMenu">
        <button mat-menu-item (click)="addLehre()">Lehre/Berufsausbildung</button>
        <button mat-menu-item (click)="addStudium()">Studium</button>
      </mat-menu>
    </div>

    <div class="form-array" formArrayName="ausbildung">
      <div class="mat-headline-3">Schule</div>
      <ng-container *ngFor="let ausb of ausbildung.controls; let i=index">
        <app-cv-education *ngIf="isSchule(ausb)"
                          [group]="ausb"
                          class="form-array-item"
                          (remove)="removeAusbildung(i)"></app-cv-education>
      </ng-container>
      <mat-error *ngIf="form.invalid && form.touched && form.get('bewerbungsunterlagen.ausbildung').errors && form.get('bewerbungsunterlagen.ausbildung').errors.schuleRequired">Bitte mindestends einen Eintrag zu Schule hinzufügen.</mat-error>
      <button type="button" mat-raised-button color="accent" (click)="addSchule()">SCHULE HINZUFÜGEN</button>
    </div>

    <div class="form-array" formArrayName="ehrenamt">
      <div class="mat-headline-3">Ehrenamt</div>
      <app-cv-volunteering class="form-array-item"
                           *ngFor="let amt of ehrenamt.controls; let i=index"
                           [group]="amt"
                           (remove)="removeEhrenamt(i)"></app-cv-volunteering>
      <button type="button" mat-raised-button color="accent" (click)="addEhrenamt()">EHRENAMT HINZUFÜGEN</button>
    </div>

    <app-cv-entry title="Deine Interessen und sonstigen Aktivitäten" color="light">
      <p>Hier können z.B. auch Stipendien und Auszeichnungen eingetragen werden, aber auch Freizeitbeschäftigungen</p>
      <mat-form-field fxFlexFill class="mat-quill-field">
        <mat-quill [styles]="{height: '200px'}"
                   [modules]="quillModules"
                   [placeholder]="'Deine Interessen und sonstigen Aktivitäten'"
                   [sanitize]="true"
                   [trimOnValidation]="true"
                   [maxLength]="sonstigesMaxLength"
                   (onContentChanged)="sonstigesChanged($event);"
                   formControlName="sonstiges"></mat-quill>
        <mat-hint [class.mat-error]="form.get('bewerbungsunterlagen.sonstiges').errors && form.get('bewerbungsunterlagen.sonstiges').errors.maxlength">{{sonstigesCount}}/{{sonstigesMaxLength}}</mat-hint>
      </mat-form-field>
    </app-cv-entry>
    <app-cv-entry title="Deine Motivation" color="light">
      <p>Du bist interessant für uns! Wir freuen uns zu erfahren, warum du dich für eine Vertriebspartnerschaft
        interessierst und warum du dich für eine Bewerbung bei TauRes entschieden hast.
        Alternativ kannst du dein Motivationsschreiben unter "Deine Dokumente" hochladen.
      </p>
      <mat-form-field fxFlexFill class="mat-quill-field">
        <mat-quill [styles]="{height: '200px'}"
                   [modules]="quillModules"
                   [placeholder]="'Deine Motivation'"
                   [sanitize]="true"
                   [trimOnValidation]="true"
                   [maxLength]="motivationMaxLength"
                   (onContentChanged)="motivationChanged($event);"
                   formControlName="motivation"></mat-quill>
        <mat-hint [class.mat-error]="form.get('bewerbungsunterlagen.motivation').errors && form.get('bewerbungsunterlagen.motivation').errors.maxlength">{{motivationCount}}/{{motivationMaxLength}}</mat-hint>
        <mat-hint class="mat-error" [align]="'end'" *ngIf="form.get('bewerbungsunterlagen.motivation').errors && form.get('bewerbungsunterlagen.motivation').errors.minLength">Mindestens 1500 Zeichen eingeben.</mat-hint>
      </mat-form-field>
    </app-cv-entry>
  </ng-container>

  <app-cv-documents [documents]="form.get('dokumente')"></app-cv-documents>

  <span>Die Unterlagen können nach dem Absenden nicht mehr bearbeitet werden.</span>
  <button type="submit" mat-raised-button color="accent" [loading]="loading | async">BEWERBUNG ABSENDEN</button>
  <mat-error *ngIf="error | async">Fehler beim Laden von Anwendungsdaten.</mat-error>
  <mat-error *ngIf="form.invalid && form.touched">Einige Einträge sind nicht gültig oder fehlen. Bitte überprüfe das Formular.</mat-error>

</form>
