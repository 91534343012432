import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {COUNTRY_CODES} from '../../country-codes';
import {Store} from '@ngrx/store';
import {State} from '../../vp-store';
import {Subject} from 'rxjs';
import {isLoading, selectApplicant, selectDeadline, selectError, selectVPManager} from '../../vp-store/vp.selector';
import {map, takeUntil} from 'rxjs/operators';
import {fileSizeValidator} from '../cv-documents/cv-documents.component';
import {loadVPManager, loadVPManagerError, sendCV} from '../../vp-store/vp.actions';
import {MatDialog} from '@angular/material/dialog';
import {TAETIGKEIT_MAX_LENGTH} from '../cv-volunteering/cv-volunteering.component';
import {schuleRequiredValidator} from '../cv-education/cv-education.component';
import {CvConfimComponent} from '../cv-confim/cv-confim.component';
import {DeadlineDialogComponent} from '../deadline-dialog/deadline-dialog.component';

import * as moment from 'moment';
import {ContactData} from '../../service/applicant.service';

const SONSTIGES_MAX_LENGTH = 2000;
const MOTIVATION_MAX_LENGTH = 8000;
const MOTIVATION_MIN_LENGTH = 1500;


const urlValidator: ValidatorFn = (control: AbstractControl) => {
  if (!control.value) {
    return null;
  }
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    new URL(control.value);
  } catch {
    return {invalidUrl: true};
  }
  return null;
};


@Component({
  selector: 'app-vp-curriculum-vitae',
  templateUrl: './vp-curriculum-vitae.component.html',
  styleUrls: ['./vp-curriculum-vitae.component.scss']
})
export class VpCurriculumVitaeComponent implements OnInit, OnDestroy {
  sortedCountries: { code: string, name: string }[] = COUNTRY_CODES;
  form = this.fb.group({
    interessent: this.fb.group({
      geschlecht: [null, [Validators.required]],
      vorname: [null, [Validators.required, Validators.maxLength(81)]],
      nachname: [null, [Validators.required, Validators.maxLength(50)]],
      geburtsname: [null, [Validators.maxLength(50)]],
      rufname: [null, [Validators.maxLength(50)]],
      geburtsdatum: [null, [Validators.required]],
      nationalitaet: [null, [Validators.required]],
      strasse: [null, [Validators.required, Validators.maxLength(150)]],
      hausnummer: [null, [Validators.required, Validators.maxLength(20)]],
      plz: [null, [Validators.required, Validators.pattern(/^\d+$/), Validators.maxLength(30)]],
      stadt: [null, [Validators.required, Validators.maxLength(100)]],
      telefonMobil: [null, [
        Validators.pattern(/^([+0])\d+[\d \/\-.]*\d+$/),
        Validators.maxLength(100),
        Validators.required]
      ],
      telefonPrivat: [null, [Validators.pattern(/^([+0])\d+[\d \/\-.]*\d+$/), Validators.maxLength(100)]],
      email: [null, [
        Validators.pattern(/^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)+$/),
        Validators.maxLength(100),
        Validators.required]
      ]
    }),
    bewerbungsunterlagen: this.fb.group({
      bild: null,
      berufserfahrung: this.fb.array([]),
      ausbildung: this.fb.array([], [schuleRequiredValidator]),
      ehrenamt: this.fb.array([]),
      sonstiges: '',
      motivation: ''
    }),
    dokumente: [[], [Validators.required, Validators.maxLength(10), fileSizeValidator(15 * 1024 * 1024)]]
  });

  quillModules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{list: 'ordered'}, {list: 'bullet'}],
      ['clean'],
    ]
  };

  anmeldeschluss: Date;
  manager: ContactData;
  loading = this.store.select(isLoading);
  error = this.store.select(selectError)
    .pipe(map(e => e && e === loadVPManagerError));

  motivationCount = 0;
  sonstigesCount = 0;

  private destroy = new Subject<void>();

  constructor(private fb: UntypedFormBuilder,
              private store: Store<State>,
              private dialog: MatDialog) {
    store.select(selectApplicant)
      .pipe(takeUntil(this.destroy))
      .subscribe(value => {
        const interessent = this.form.get('interessent');
        interessent.patchValue(value);
      });

    this.store.select(selectDeadline)
      .pipe(takeUntil(this.destroy))
      .subscribe(value => {
        this.anmeldeschluss = value;
      });

    this.store.select(selectVPManager)
      .pipe(takeUntil(this.destroy))
      .subscribe(value => {
        this.manager = value;
      });
  }

  get berufserfahrung(): UntypedFormArray {
    return this.form.get('bewerbungsunterlagen.berufserfahrung') as UntypedFormArray;
  }

  get ausbildung(): UntypedFormArray {
    return this.form.get('bewerbungsunterlagen.ausbildung') as UntypedFormArray;
  }

  get ehrenamt(): UntypedFormArray {
    return this.form.get('bewerbungsunterlagen.ehrenamt') as UntypedFormArray;
  }

  get sonstigesMaxLength(): number {
    return SONSTIGES_MAX_LENGTH;
  }

  get motivationMaxLength(): number {
    return MOTIVATION_MAX_LENGTH;
  }

  sonstigesChanged(event: any): void {
    this.sonstigesCount = event.text.length;
    const control = this.form.get('bewerbungsunterlagen.sonstiges');
    if (this.sonstigesCount > SONSTIGES_MAX_LENGTH) {
      control.setErrors({maxlength: SONSTIGES_MAX_LENGTH});
    } else {
      control.setErrors(null);
    }
  }

  motivationChanged(event: any): void {
    this.motivationCount = event.html ? event.text.length - 1 : 0;
    const control = this.form.get('bewerbungsunterlagen.motivation');
    control.setErrors(null);
    if (this.motivationCount > 0 && this.motivationCount <= 1500) {
      control.setErrors({minLength: MOTIVATION_MIN_LENGTH});
    }
    if (this.motivationCount > MOTIVATION_MAX_LENGTH) {
      control.setErrors({maxlength: MOTIVATION_MAX_LENGTH});
    }
  }

  ngOnInit(): void {
    this.store.dispatch(loadVPManager());
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  addBerufserfahrung(): void {
    this.berufserfahrung.push(this.buildBerufserfahrung());
  }

  addEhrenamt(): void {
    this.ehrenamt.push(this.buildEhrenamt());
  }

  removeBerufserfahrung(i: number): void {
    this.berufserfahrung.removeAt(i);
  }

  removeEhrenamt(i: number): void {
    this.ehrenamt.removeAt(i);
  }

  addSchule(): void {
    this.ausbildung.push(this.buildSchule());
  }

  addStudium(): void {
    this.ausbildung.push(this.buildStudium());
  }

  addLehre(): void {
    this.ausbildung.push(this.buildLehre());
  }

  removeAusbildung(i: number): void {
    this.ausbildung.removeAt(i);
  }

  isSchule(ausbildung: AbstractControl): boolean {
    return ausbildung.get('typ').value === 'schule';
  }

  onSubmit(): void {
    if (moment().isAfter(this.anmeldeschluss)) {
      this.dialog.open(DeadlineDialogComponent, {data: {anmeldeschluss: this.anmeldeschluss, manager: this.manager.person}});
      return;
    }
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      const firstElementWithError = document.querySelector('.ng-invalid[formControlName]') as HTMLInputElement;
      if (firstElementWithError) {
        firstElementWithError.focus();
      }
      return;
    }
    this.dialog.open(CvConfimComponent)
      .afterClosed()
      .pipe(takeUntil(this.destroy))
      .subscribe(confirmed => {
        if (confirmed) {
          const interessent = this.form.get('interessent').value;
          const bewerbungsunterlagen = this.form.get('bewerbungsunterlagen').value;
          // remove image if only filters are set, but no image
          if (bewerbungsunterlagen.bild && !bewerbungsunterlagen.bild.src) {
            bewerbungsunterlagen.bild = null;
          }
          const dokumente = this.form.get('dokumente').value;
          this.store.dispatch(sendCV({data: {interessent: {...interessent, bewerbungsunterlagen}, dokumente}}));
        }
      });
  }

  private buildSchule(): UntypedFormGroup {
    return this.fb.group({
      name: ['', [Validators.required]],
      abschluss: null,
      abschlussTyp: null,
      ende: null,
      typ: 'schule'
    });
  }

  private buildStudium(): UntypedFormGroup {
    return this.fb.group({
      hochschule: ['', [Validators.required]],
      fachrichtung: null,
      abschluss: null,
      andererAbschluss: null,
      abschlussTyp: null,
      typ: 'studium',
      von: [null, [Validators.required]],
      bis: [null, []]
    });
  }

  private buildLehre(): UntypedFormGroup {
    return this.fb.group({
      unternehmen: ['', [Validators.required]],
      branche: null,
      abschluss: null,
      abschlussTyp: 'ABGESCHLOSSENE_BERUFSAUSBILDUNG',
      typ: 'lehre',
      von: [null, [Validators.required]],
      bis: [null, []]
    });
  }

  private buildBerufserfahrung(): UntypedFormGroup {
    return this.fb.group({
      positionsbezeichnung: ['', [Validators.required]],
      beschaeftigungsart: '',
      unternehmen: '',
      url: ['', [urlValidator]],
      branche: '',
      von: [null, [Validators.required]],
      bis: [null, []]
    });
  }

  private buildEhrenamt(): UntypedFormGroup {
    return this.fb.group({
      institution: ['', [Validators.required]],
      taetigkeit: ['', [Validators.maxLength(TAETIGKEIT_MAX_LENGTH)]],
      von: [null, [Validators.required]],
      bis: [null, []]
    });
  }
}
