import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {State} from '../../vp-store';
import {loadAWEManager, loadVPManager} from '../../vp-store/vp.actions';
import {Observable} from 'rxjs';
import {ApplicantService, ContactData} from '../../service/applicant.service';
import {selectAWEManager, selectState, selectToken, selectVPManager} from '../../vp-store/vp.selector';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-vp-landing',
  templateUrl: './vp-landing.component.html',
  styleUrls: ['./vp-landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VpLandingComponent implements OnInit{

  aweManager: Observable<ContactData>;
  vpManager: Observable<ContactData>;
  aweMangerUrl: Observable<string>;
  vpMangerUrl: Observable<string>;
  token: Observable<string>;
  constructor(private store: Store<State>, private service: ApplicantService) {
    this.aweManager = this.store.select(selectAWEManager);
    this.vpManager = this.store.select(selectVPManager);

    this.aweMangerUrl = this.store.select(selectState)
      .pipe(map(state => this.service.getAWEManagerUrl(state.token, state.birthdate))
    );
    this.vpMangerUrl = this.store.select(selectState)
      .pipe(map(state => this.service.getVPManagerUrl(state.token, state.birthdate))
      );
    this.token = this.store.select(selectToken);
  }

  ngOnInit(): void {
    this.store.dispatch(loadAWEManager());
    this.store.dispatch(loadVPManager());
  }
}
