import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {selectVerifyResult} from '../vp-store/vp.selector';
import {map} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {State} from '../vp-store';

@Injectable({
  providedIn: 'root'
})
export class TokenGuard implements CanActivate {
  constructor(private readonly store: Store<State>, private readonly router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {

    return this.store.select(selectVerifyResult)
      .pipe(map(verifyResult => {
        const token = route.paramMap.get('token');
        const next = state.url;
        if (!verifyResult) {
          return this.router.createUrlTree(['/vp', token], { queryParams: { next } });
        }
        return true;
      }));
  }
}
