<img *ngIf="trustedImageUrlURL" class="contact-card-image" [src]="trustedImageUrlURL" [alt]="contactData?.person.nachname">
<div class="contact-card-content">
  <div class="contact-card-name">{{contactData?.person.vorname}} {{contactData?.person.nachname}}</div>
  <div class="contact-card-job-title">{{jobTitle}}</div>
  <div class="contact-card-contacts-container">
    <div class="contact-card-contact" *ngIf="contactData.email">
      <mat-icon>email</mat-icon>
      <a href="mailto:{{contactData.email}}">{{contactData.email}}</a>
    </div>

    <div class="contact-card-contact" *ngIf="contactData.telefon">
      <mat-icon>phone</mat-icon>
      <a href="tel:{{contactData.telefon}}">{{contactData.telefon}}</a>
    </div>
  </div>
</div>
