export const BRANCHES: string[] = ['Architektur und Bauwesen',
  'Automobil und Fahrzeugbau',
  'Banken und Finanzdienstleistungen',
  'Beratung und Consulting',
  'Energie, Wasser und Umwelt',
  'Erziehung, Bildung und Wissenschaft',
  'Gesundheit und Soziales',
  'Immobilien',
  'Industrie und Maschinenbau',
  'Internet und Informationstechnologie',
  'Konsumgüter und Handel',
  'Kunst, Kultur und Sport',
  'Marketing, PR und Design',
  'Medien und Verlage',
  'Öffentlicher Dienst, Verbände und Einrichtungen',
  'Personaldienstleistungen',
  'Pharma und Medizintechnik',
  'Telekommunikation',
  'Tourismus und Gastronomie',
  'Transport und Logistik',
  'Versicherungen',
  'Wirtschaftsprüfung, Steuern und Recht',
  'Sonstige Branchen',
];
