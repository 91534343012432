import {ChangeDetectionStrategy, Component, Input, OnDestroy} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {UntypedFormControl, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {selectRouteData, State} from '../../vp-store';
import {hasError, isLoading} from '../../vp-store/vp.selector';
import {accept} from '../../vp-store/vp.actions';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-pdf-view-accept',
  templateUrl: './pdf-view-accept.component.html',
  styleUrls: ['./pdf-view-accept.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfViewAcceptComponent implements OnDestroy {
  control = new UntypedFormControl(false, Validators.requiredTrue);
  error = this.store.select(hasError);
  loading = this.store.select(isLoading);
  private $pdfUrl: string;
  private stateProperty: string;
  private trustedURL: SafeResourceUrl;
  private destroyed = new Subject<void>();

  constructor(private readonly sanitizer: DomSanitizer,
              private readonly store: Store<State>) {
    this.store.select(selectRouteData)
      .pipe(takeUntil(this.destroyed))
      .subscribe(data => {
        this.stateProperty = data.stateProperty;
        this.pdfUrl = data.pdfUrl;
      });
  }

  get pdfUrl(): string {
    return this.$pdfUrl;
  }

  @Input()
  set pdfUrl(value: string) {
    this.$pdfUrl = value;
    this.trustedURL = this.sanitizer.bypassSecurityTrustResourceUrl('/api/vp/' + this.$pdfUrl);
  }

  get dataURL(): SafeResourceUrl {
    return this.trustedURL;
  }

  get title(): string {
    if (!this.$pdfUrl) {
      return '';
    }
    return this.$pdfUrl.substring(0, this.$pdfUrl.lastIndexOf('.')).replace(/^[a-zA-z]|\s(.)/ig, L => L.toUpperCase());
  }

  accept(): void {
    this.store.dispatch(accept({document: this.$pdfUrl, stateProperty: this.stateProperty}));
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
