import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {VpVerifyComponent} from './components/vp-verify/vp-verify.component';
import {PdfViewAcceptComponent} from './components/pdf-view-accept/pdf-view-accept.component';
import {VpLandingComponent} from './components/vp-landing/vp-landing.component';
import {VerifyResultGuard} from './guards/verify-result.guard';
import {CvSubmitSuccessComponent} from './components/cv-submit-success/cv-submit-success.component';
import {VpCurriculumVitaeComponent} from './components/vp-curriculum-vitae/vp-curriculum-vitae.component';
import {CvSubmittedGuard} from './guards/cv-submitted.guard';
import {TokenGuard} from './guards/token.guard';
import {RedirectGuard} from './guards/redirect.guard';
import {CvDeadlineComponent} from './components/cv-deadline/cv-deadline.component';
import {CvDeadlineGuard} from './guards/cv-deadline.guard';

const routes: Routes = [
  {
    path: `vp/:token`,
    component: VpVerifyComponent,
  }, {
    path: 'vp/:token/datenschutz',
    component: PdfViewAcceptComponent,
    canActivate: [TokenGuard],
    data: {
      pdfUrl: 'Datenschutzeinwilligung.pdf',
      stateProperty: 'datenschutzeinwilligung'
    }
  }, {
    path: 'vp/:token/verschwiegenheit',
    component: PdfViewAcceptComponent,
    canActivate: [TokenGuard],
    data: {
      pdfUrl: 'Verschwiegenheitsverpflichtung.pdf',
      stateProperty: 'verschwiegenheitsverpflichtung'
    }
  }, {
    path: `vp/:token/landing`,
    component: VpLandingComponent,
    canActivate: [VerifyResultGuard]
  }, {
    path: 'vp/:token/cv',
    component: VpCurriculumVitaeComponent,
    canActivate: [VerifyResultGuard, CvSubmittedGuard, CvDeadlineGuard]
  }, {
    path: 'vp/:token/cv-submitted',
    component: CvSubmitSuccessComponent,
    canActivate: [VerifyResultGuard]
  }, {
    path: 'vp/:token/cv-deadline',
    component: CvDeadlineComponent,
    canActivate: [VerifyResultGuard]
  }, {
    path: '**',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://www.taures.de/'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Restore the last scroll position
    scrollPositionRestoration: 'enabled',
    scrollOffset: [0, 0],
    // Enable scrolling to anchors
    anchorScrolling: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
