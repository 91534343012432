<app-cv-entry title="Deine Dokumente" color="light">
  <p>Wir bitten darum, die Abschlusszeugnisse (Schule/Berufsausbildung/Studium) hochzuladen (verpflichtend).</p>
  <p><strong>ACHTUNG: Das Abschlusszeugnis der Berufsausbildung ist kein Ersatz für das Abschlusszeugnis der Schule.</strong></p>
  <p>Zusätzlich freuen wir uns über Arbeitszeugnisse (nicht verpflichtend). Sollte noch kein Schulabschluss erreicht sein, benötigen wir das letzte vorhandene Zeugnis. Die Abschlusszeugnisse sollten im PDF-Format hochgeladen werden.
    Zudem benötigen wir Dein Motivationsschreiben, sofern die Motivation nicht schon unter <strong>Deine Motivation</strong> dargelegt wurde.</p>
  <p style="margin-bottom: 3px">Beschriftungen bitte wie folgt:</p>
  <ul>
    <li>Nachname_Vorname_Art des Abschlusses</li>
    <li>Nachname_Vorname_Motivationsschreiben</li>
  </ul>
  <p>Alle Dokumente zusammen dürfen nicht größer als 15 MB sein.</p>
  <div trDnd
        (fileDropped)="onFilesDropped($event)"
        accept="application/pdf"
        (click)="fileInput.click()">
    <button type="button" mat-mini-fab color="accent" style="color: white">
      <mat-icon>add</mat-icon>
    </button>
    <span>oder PDF-Dateien auf dieses Feld ziehen</span>
  </div>
  <mat-progress-bar color="accent" mode="indeterminate" [ngStyle]="{'visibility':processingFiles === true ? 'visible' : 'hidden'}" class="file-process-spinner"></mat-progress-bar>
  <input #fileInput type="file" (change)="onFileChange($event)" hidden/>

  <div fxLayout="column" fxLayoutGap="16px" fxFlexFill class="document-list">
    <div fxLayout="row" fxLayoutGap="16px" class="item" fxLayoutAlign="start center" *ngFor="let doc of documents.value let i=index">
      <div fxFlex="80%" class="filename">{{doc.filename}}</div>
      <div fxFlex="20%" class="file-size">{{size(doc.data) | bytes}}</div>
      <button fxFlex="48px" mat-icon-button type="button" (click)="remove(i)"><mat-icon>delete</mat-icon></button>
    </div>
    <mat-error *ngIf="documents.errors && documents.errors.required">
      Wir benötigen mindestens das Zeugnis deines letzten Schulbesuchs im PDF-Format.
    </mat-error>
    <mat-error *ngIf="documents.errors && documents.errors.maxlength">
      Bitte nicht mehr als {{documents.errors.maxlength.requiredLength}} Dokumente anfügen.
    </mat-error>
    <mat-error *ngIf="documents.errors && documents.errors.fileSize">
      Alle Dokumente dürfen zusammen nicht mehr als {{documents.errors.fileSize.max | bytes}} groß sein.
    </mat-error>
  </div>

</app-cv-entry>
