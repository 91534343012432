<mat-form-field>
  <mat-label>{{label}}</mat-label>
  <input matInput [min]="min" [max]="max" [matDatepicker]="picker" [placeholder]="label" [formControl]="control"  [required]="required">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker
                  (yearSelected)="chosenYearHandler($event, control)"
                  (monthSelected)="chosenMonthHandler($event, picker, control)"
                  startView="multi-year"></mat-datepicker>
  <mat-hint style="color: #0009;">{{hint}}</mat-hint>
</mat-form-field>
